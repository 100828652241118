<template>
    <v-container>
        <v-card slot="rightContainer" outlined>
            <v-card-title class="grey lighten-4">
                <b>HISTORICO COMPRAS/GASTOS </b>
                <v-spacer />
                <v-col cols="12" sm="3">
                    <v-menu
                        ref="menu"
                        v-model="menu3"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template #activator="{ on, attrs }">
                            <v-text-field
                                v-model="fechaFiltro"
                                hide-details="auto"
                                dense
                                label="FILTRAR POR RANGO - MES"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="fechaFiltro" range no-title scrollable>
                            <v-spacer />
                            <v-btn text color="primary" @click="menu3 = false"> CERRAR </v-btn>
                            <v-btn color="primary" :loading="loadingLista" @click="getLista"> BUSCAR </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field dense v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line hide-details />
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12">
                        <v-data-table multi-sort :search="search" :headers="headers" :items="_listaGastos" class="elevation-1" :items-per-page="20">
                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="green lighten-2" small @click="seeDescription(item)">mdi-eye</v-icon>
                                    </template>
                                    <span>Observar descripcion</span>
                                </v-tooltip>
                                <!-- <v-icon small @click="editItem(item)" color="green">mdi-pencil</v-icon> -->

                                <!-- <v-icon small @click="deleteItem(item)" color="primary">mdi-delete</v-icon> -->
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog transition="scroll-x-transition" v-model="dialogDescription" max-width="400">
            <v-card elevation="15" outlined shaped>
                <v-card-title class="font-weight-medium grey lighten-4 mb-3">Descripcion </v-card-title>

                <v-card-text v-html="dialogText"> </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialogDescription = false"> Cerrar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { format, subDays, addDays } from 'date-fns'
import ItemGastos from '../../components/gastos/ItemGastos'
import ItemPagos from '../../components/gastos/ItemPagos'
import parseISO from 'date-fns/esm/parseISO/index.js'
import { formatInTimeZone } from 'date-fns-tz'

export default {
    components: {
        ItemGastos,
        ItemPagos
    },
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            dialogText: '',
            dialogDescription: false,
            fechaFiltro: [format(subDays(new Date(), 31), 'yyyy-MM-dd'), format(addDays(new Date(), 1), 'yyyy-MM-dd')],
            menu3: false,
            openItemTable: false,
            menu: false,
            itemTable: null,
            dialog3: false,
            menu2: false,
            loadingFile: false,
            file: null,
            checkbox: false,
            itemsCompra: [],
            comprasFacturacion: [],
            search: '',
            loadingLista: false,
            step: 1,
            fecha: format(new Date(), 'yyyy-MM-dd'),
            showSucces: { state: false, text: 'Agregado exitosamente' },
            loading: false,
            form: DefaultForm.formCompras(),
            formProv: DefaultForm.formProveedor(),
            openModalProveedor: false,
            description: null,
            efectivo: null,
            loading2: false,
            dialog: false,
            headers: [
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'fechaEmision', value: 'fechaCreacion' },
                { text: 'Tip. Comprobante', value: 'nameTipoComprobante' },
                { text: 'Nom. Comprobante', value: 'numeroComprobante' },

                { text: 'Doc. Proveedor', value: 'perNumeroDocumento' },
                { text: 'Nomb. Proveedor', value: 'perNombresCompleto' },
                { text: 'Descripción', value: 'description' },
                { text: 'ImporteTotal', value: 'importe' }

                // { text: 'ImporteAbonado S/.', value: 'importeAbonadoSoles' },
                // { text: 'ImporteAbonado $', value: 'importeAbonadoDolares' }
            ]
        }
    },
    computed: {
        ...mapState('utilities', [
            'tipoPago',
            'tipoBancoDestino',
            'listaCategoria',
            'tipoMoneda',
            'tipoDocumento',
            'tipoComprobante',
            'tipoComprobanteFact'
        ]),
        ...mapGetters('gastos', ['listaGastos']),
        ...mapState('compras', ['datos']),
        ...mapState('users', ['user']),
        ...mapGetters('proveedor', ['listaProveedor']),
        _listaGastos() {
            let newLista = []
            console.log('comprasFacturacion', this.comprasFacturacion)
            if (this.comprasFacturacion) {
                if (this.comprasFacturacion.length > 0) {
                    this.comprasFacturacion.map((x) => {
                        const tipo_Comprobante = this.tipoComprobanteFact.find((y) => y.id == Number(x.idTipoComprobante))
                        console.log('tipo_Comprobante', tipo_Comprobante)
                        newLista.push({
                            ...x,
                            fechaEmision: x.fechaEmision,

                            nameTipoComprobante: x.tipoComprobante
                        })
                    })
                }
            }
            return newLista.sort((a, b) => a.fechaCreacion - b.fechaCreacion)
        },
        id_tipo_gasto() {
            return this.datos.id_tipo_gasto
        },
        fechaAdd() {
            return this.datos.fechaEmision
        },
        idTipoPago() {
            return this.datos.tipoPago
        }
    },
    watch: {
        id_tipo_gasto(val) {
            if (val) {
                ////console.log("listaCategoria ", this.listaCategoria, val);
                const value = this.listaCategoria.find((x) => x.id_tipo_gasto == val)
                ////console.log("value : ", value);
                this.datos.description = value.tipo_gasto
            }
        },
        idTipoPago(val) {}
    },
    async created() {
        //console.log('datos', this.datos)
        this.$store.commit('mainUI/OPEN_BAR', {
            state: false
        })
        await this.$store.dispatch('pagos/tipoCambio')

        const compras = DefaultForm.formCompras()
        //console.log('compras ', compras)
        this.$store.commit('compras/UPDATE_COMPRA', compras)
        this.$store.commit('compras/UPDATE_LISTA_PAGOS', [])
        await this.postInitCompra()
        await this.getLista()
        await this.getListaProveedor(false)
        //console.log('this._listaGastos', this._listaGastos)
    },

    methods: {
        ...mapActions({
            handlePost: 'compras/sendPurchase'
        }),
        seeDescription(item) {
            this.dialogDescription = true
            let txt = ''
            console.log('item', item)
            JSON.parse(item.itemGastos).map((x) => {
                console.log('x', x)
                if (x.description) {
                    txt = txt.concat(
                        '• ',
                        x.description,
                        ' - <strong>',
                        x.quantity ? x.quantity.concat(' Unidades -') : x.unidades ? x.unidades.toString().concat(' Unidades -') : '',
                        x.idTipoMoneda == 1 ? ' </strong>  S/.' : ' $',
                        x.total || x.importe,
                        '<br/>'
                    )
                } else {
                    const items = JSON.parse(x.item)
                    txt = txt.concat(
                        '• ',
                        items.description,
                        ' - <strong>',
                        x.quantity ? x.quantity.concat(' Unidades -') : x.unidades ? x.unidades.toString().concat(' Unidades -') : '',
                        x.idTipoMoneda == 1 ? ' </strong>  S/.' : ' $',
                        x.total || x.importe,
                        '<br/>'
                    )
                }
            })
            this.dialogText = txt
        },
        async getAllCaja(type) {
            const result = await this.$store.dispatch('compras/getFromcompras', { typeDate: 2, fechaFiltro: this.fechaFiltro, gastos: this.listaGastos })
            console.log('result--- ', result)
            this.comprasFacturacion = result
        },
        async upload() {
            //console.log('this.file_', this.file)
            this.loadingFile = true
            await this.handlePost({ file: this.file, user: this.user })
            this.loadingFile = false
            this.dialog3 = true
            const compras = DefaultForm.formCompras()
            this.$store.commit('compras/UPDATE_COMPRA', compras)
            this.$store.commit('compras/UPDATE_LISTA_PAGOS', [])
            this.file = []

            await this.getLista()
            await this.postInitCompra()
        },
        async postInitCompra() {
            await this.$store.dispatch('compras/postInitCompras')
        },
        async addProveedor(name) {
            this.$validator
                .validateAll(name)
                .then(async (result) => {
                    if (result) {
                        this.loading2 = true
                        // //console.log(this.listaCategoria);
                        // //console.log(detalle);
                        //console.log('start _provee')
                        const resultProv = await this.$store.dispatch('proveedor/createCliente', this.formProv)
                        this.datos.idProveedor = resultProv.id
                        this.formProv = DefaultForm.formProveedor()
                        await this.getListaProveedor(true)
                        //console.log('start _provee')
                        this.loading2 = false
                        this.openModalProveedor = false
                    }
                })
                .catch(() => {
                    this.loading2 = false
                })
        },
        openItem(item) {
            this.openItemTable = true
            this.itemTable = item
        },
        async deleteItem(item) {
            await this.$store.dispatch('gastos/deleteGasto', { id: item.id })
            this.$store.commit('mainUI/OPEN_BAR', {
                state: true,
                text: 'Eliminado satisfactoriamente'
            })
            await this.getLista()
        },
        async addGastos(name) {
            this.$validator
                .validateAll(name)
                .then(async (result) => {
                    if (result) {
                        this.loading = true

                        let importeTotal = 0
                        let items = []
                        this.itemsCompra.map((x) => {
                            importeTotal += parseFloat(x.importe)
                            items.push({ description: x.description, importe: x.importe, unidades: x.unidades, idTipoMoneda: x.idTipoMoneda })
                        })
                        const body = {
                            ...this.datos,
                            importe: importeTotal,
                            itemGastos: items,
                            status: 1
                        }
                        let mensaje = this.step == 1 ? 'Agregado satisfactoriamente' : 'Actualizado satisfactoriamente'
                        await this.$store.dispatch('gastos/updateGastoxId', body)

                        this.step = 1
                        this.$store.commit('mainUI/OPEN_BAR', {
                            state: true,
                            text: mensaje
                        })

                        this.fnItemsCompra([])
                        await this.getLista()
                        this.$store.commit('compras/UPDATE_LISTA_PAGOS', [])

                        this.$store.commit('compras/RESET_COMPRA', {})
                        await this.postInitCompra()

                        this.loading = false
                    }
                })
                .catch(() => {
                    this.$store.commit('mainUI/OPEN_BAR', {
                        state: true,
                        text: 'Hubo un error en los datos'
                    })
                    this.loading = false
                })
        },
        async getLista() {
            console.log('hola')
            this.loadingLista = true
            await this.$store.dispatch('gastos/getLista', { fechaFiltro: this.fechaFiltro })
            this.getAllCaja()
            this.loadingLista = false
            this.menu3 = false
        },
        async getListaProveedor(payload) {
            await this.$store.dispatch('proveedor/getLista', payload)
        },
        fnItemsCompra(val) {
            this.itemsCompra = val
        },
        async getDatosExternal() {
            //console.log('this.formProv.numeroDocumento.length ', this.formProv.numeroDocumento.length)
            if (this.formProv.numeroDocumento.length >= 8) {
                try {
                    this.$store.commit('mainUI/OPEN_MODAL', {
                        state: true,
                        text: 'Buscando en Reniec/Sunat',
                        color: 'green'
                    })
                    const resultado = await this.$store.dispatch('proveedor/getClienteExternal', {
                        idTipoDocumento: this.formProv.idTipoDocumento,
                        numeroDocumento: this.formProv.numeroDocumento
                    })
                    //console.log('resultado ', resultado)
                    if (resultado.id) {
                        //console.log('find mysql')
                        await this.getListaProveedor(true)

                        /* se encuentra en la db */
                        this.formProv.id = resultado.idProveedor || ''
                        this.formProv.full_name = resultado.full_name || ''
                        this.formProv.distrito = resultado.distrito || ''
                        this.formProv.departamento = resultado.departamento || ''
                        this.formProv.provincia = resultado.provincia || ''
                        this.formProv.direccion = resultado.direccion || ''
                        this.datos.idProveedor = resultado.idProveedor
                        this.openModalProveedor = false
                    } else {
                        this.formProv = {
                            ...this.formProv,
                            ...resultado
                        }
                    }
                    //console.log('this.formProv ', this.formProv)

                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })
                } catch (error) {
                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })

                    //console.log('error ', error)
                }
            }
        }
    },
    async created() {
        await this.getLista()
        await this.getAllCaja(1)
    }
}
</script>
